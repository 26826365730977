import React, { useEffect } from 'react';

import { ResourcesProps } from '../../pages/Resources';
import { IDLE } from '../builders/state';
import { useAppDispatch, useAppSelector } from '../hooks';
import { fetchResources } from '../slices/Resources';

const withResourcesContainer = (Resources: React.FC<ResourcesProps>) => {
    const ResourcesContainer: React.FC = (props) => {
        const dispatch = useAppDispatch();
        const resources = useAppSelector((state) => state.resources.data);
        const status = useAppSelector((state) => state.resources.status);

        useEffect(() => {
            if (status === IDLE) {
                dispatch(fetchResources());
            }
        }, [status, dispatch]);

        return <Resources resources={resources} {...props} />;
    };

    return ResourcesContainer;
};

export default withResourcesContainer;
