import { default as LoadingGIF } from '../../icons/Loading';

const Loading = () => {
    return (
        <div className="absolute grid h-full w-full place-content-center">
            <LoadingGIF className="h-16 w-24" />
        </div>
    );
};

export default Loading;
