import { IExportFunction, OnExportReady } from '@gooddata/sdk-ui';
import { IInsightViewProps } from '@gooddata/sdk-ui-ext';

import ExportDropdown from '../../components/insights/Export';
import Insight from './InsightView';

const InsightWithExport = (props: IInsightViewProps) => {
    let onExport: IExportFunction | null = null;

    const onExportReady: OnExportReady = (callback: IExportFunction) => {
        onExport = callback;
    };

    return (
        <div className="relative">
            <ExportDropdown onExport={(props) => (onExport as IExportFunction)(props)} />
            <Insight {...props} onExportReady={onExportReady} />
        </div>
    );
};

export default InsightWithExport;
