import { Auth0ContextInterface, useAuth0, User } from '@auth0/auth0-react';

import { isDevelopment } from './Development';

export const useProvider = (): Auth0ContextInterface<User> => {
    const auth0Context = useAuth0() as Auth0ContextInterface<User>;
    if (isDevelopment()) {
        return {
            ...auth0Context,
            isAuthenticated: true,
            isLoading: false,
            user: { email: 'test.email@gmail.com', name: 'Test Name' },
        };
    }
    return auth0Context;
};
