import { ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Menu from '../icons/Menu';
import LogoWithLink from './display/LogoWithLink';
import FullPageMenu from './navigation/FullPageMenu';
import Sidebar from './navigation/Sidebar';
import TopBar from './TopBar';

type LayoutProps = {
    children: ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
    const [isFullPageMenuOpen, setFullpageIsMenuOpen] = useState(false);
    const menuOptions = [
        { path: '/', subMenu: [], title: 'Home' },
        {
            path: '/reporting',
            subMenu: [
                {
                    path: '/reporting/media-delivery',
                    subMenu: [],
                    title: 'Media Delivery',
                },
                {
                    path: '/reporting/audience-insights',
                    subMenu: [],
                    title: 'Audience Insights',
                },
            ],
            title: 'Reporting',
        },
        {
            path: '/studio-hub',
            subMenu: [],
            title: 'Studio Hub',
        },
        {
            isPositionBottom: true,
            path: '/settings',
            subMenu: [],
            title: 'Settings',
        },
        {
            isPositionBottom: true,
            path: '/faqs',
            subMenu: [],
            title: 'FAQs',
        },
    ];

    const showMenu = () => setFullpageIsMenuOpen(true);
    const hideMenu = () => setFullpageIsMenuOpen(false);
    const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
        if (event.target instanceof HTMLAnchorElement) {
            isFullPageMenuOpen ? hideMenu() : showMenu();
        }
    };

    const location = useLocation();
    let extraClassNames = '';
    if (location?.pathname === '/studio-hub') {
        extraClassNames = 'bg-fusion bg-cover';
    }
    return (
        <div className="flex h-screen w-screen" data-testid="layout">
            <div className="fixed hidden w-[15rem] shrink-0 lg:flex" data-testid="lateral-sidebar">
                <Sidebar menuOptions={menuOptions} />
            </div>
            <div
                className="fixed left-1/2 z-40 flex h-16 -translate-x-1/2 transform items-center lg:hidden"
                onClick={hideMenu}
            >
                <LogoWithLink className="h-16" />
            </div>
            <div className="fixed top-0 z-30 flex h-16  w-screen justify-end bg-gradient-to-b from-darkpetrol to-transparent lg:hidden">
                <button
                    onClick={showMenu}
                    className="rounded-xl p-5 pr-8 align-bottom text-3xl font-semibold focus:outline-none lg:hidden"
                    data-testid="menu-button"
                >
                    <Menu />
                </button>
            </div>
            <div
                className={`table h-full w-full justify-center pb-10 pt-24 lg:ml-[15rem] lg:pt-0 ${extraClassNames}`}
                data-testid="main"
            >
                <div className="hidden lg:flex">
                    <TopBar />
                </div>
                <div className="px-10 lg:px-24" data-testid="content">
                    {children}
                </div>
            </div>
            {isFullPageMenuOpen && (
                <div
                    onClick={toggleMenu}
                    className="fixed z-30 flex h-full w-full flex-col items-start justify-between space-y-8 bg-petrol p-4 lg:hidden"
                >
                    <FullPageMenu menuOptions={menuOptions} />
                </div>
            )}
        </div>
    );
};

export default Layout;
