import { Auth0Provider } from '@auth0/auth0-react';
import { PropsWithChildren, ReactNode } from 'react';

import Authentication from './Authentication';
import { isDevelopment } from './Development';

const Provider = ({ children }: PropsWithChildren<ReactNode>) => {
    if (isDevelopment()) {
        return <>{children}</>;
    }

    return (
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
            redirectUri={window.location.origin}
        >
            <Authentication>{children}</Authentication>
        </Auth0Provider>
    );
};

export default Provider;
