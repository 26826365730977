import React, { useEffect } from 'react';

import { StudioHubProps } from '../../pages/StudioHub';
import { useAppDispatch, useAppSelector } from '../hooks';
import { fetchStudioHub } from '../slices/StudioHub';

const withStudioHubContainer = (StudioHub: React.FC<StudioHubProps>) => {
    const StudioHubContainer: React.FC = (props) => {
        const dispatch = useAppDispatch();
        const data = useAppSelector((state) => state.studioHub.data);
        const status = useAppSelector((state) => state.studioHub.status);

        useEffect(() => {
            if (status === 'idle') {
                dispatch(fetchStudioHub());
            }
        }, [status, dispatch]);

        return <StudioHub data={data} {...props} />;
    };

    return StudioHubContainer;
};

export default withStudioHubContainer;
