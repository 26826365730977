import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import PageTitle from '../components/display/PageTitle';
import ExpandCollapse from '../components/section/expandCollapse/ExpandCollapse';
import { ApiFAQType, FAQType } from '../interfaces/FAQTypes';

export type FAQsProps = {
    data: ApiFAQType;
};

export default function FAQ({ data }: FAQsProps) {
    return (
        <React.Fragment>
            <PageTitle title="Frequently Asked Questions" />
            {data?.data?.map((row: FAQType) => (
                <ExpandCollapse key={row.attributes.Order} title={row.attributes.Title}>
                    <ReactMarkdown linkTarget={'_blank'} rehypePlugins={[rehypeRaw]}>
                        {row.attributes.Text}
                    </ReactMarkdown>
                </ExpandCollapse>
            ))}
        </React.Fragment>
    );
}
