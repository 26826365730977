import React, { ReactNode } from 'react';

import PageTitle from '../components/display/PageTitle';
import Slider from '../components/display/slider/Slider';
import ResourcesCards from '../components/ResourcesCards';
import { ApiHomepageSlideShowType } from '../interfaces/HomepageSlideShowTypes';
import { ApiInstagramImageType } from '../interfaces/InstagramImageTypes';
import { ApiResourceType } from '../interfaces/ResourceTypes';

export type HomeProps = {
    slides: ApiHomepageSlideShowType;
    instagramImage: ApiInstagramImageType;
    resources: ApiResourceType;
};

export default function Home({ slides, instagramImage, resources }: HomeProps) {
    return (
        <React.Fragment>
            <PageTitle title="Home" />
            <div className="mt-10 flex flex-col">
                <div className="mt-4 flex flex-col gap-6 lg:flex-row">
                    <div className="lg:w-4/6" data-testid="home-carousel">
                        <h1 className="mb-4 text-xl">News and Updates</h1>
                        <Slider time={5000}>
                            {(slides.data?.map((slide) => {
                                const image = slide.attributes.Image.data.attributes;
                                return (
                                    <img
                                        className="max-h-[42rem] w-full max-w-[82rem] lg:max-h-[42rem] lg:max-w-[82rem]"
                                        key={slide.attributes.Order}
                                        src={image.url}
                                        alt={image.alternativeText}
                                    />
                                );
                            }) as ReactNode[]) || []}
                        </Slider>
                    </div>
                    <div className="lg:w-2/6" data-testid="home-instagram">
                        <h1 className="mb-4 text-xl">Instagram</h1>
                        <a href={instagramImage.data?.attributes.URL} target="_blank" rel="noreferrer">
                            <img src={instagramImage.data?.attributes.Image.data.attributes.url} alt="" />
                        </a>
                    </div>
                </div>
                <h1 className="mt-10 inline-block text-xl">Resources</h1>
                <div className="mt-4 flex w-fit flex-col justify-center gap-6 align-middle lg:flex-row">
                    <ResourcesCards resources={resources} />
                </div>
            </div>
        </React.Fragment>
    );
}
