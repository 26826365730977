import ClearIcon from '../../icons/Clear';
import { MenuOptionsItems } from '../../interfaces/NavigationTypes';
import Profile from '../user/Profile';
import NavElement from './NavElement';

type FullPageMenuProps = {
    menuOptions: MenuOptionsItems;
};

const FullPageMenu = ({ menuOptions }: FullPageMenuProps) => {
    return (
        <div
            className="flex h-full w-full flex-col items-stretch justify-between overflow-y-auto"
            data-testid="full-page-menu"
        >
            <a className="self-end p-2 pb-8 font-semibold text-gray-400" href="/#" data-testid="clear-menu-icon">
                <ClearIcon />
            </a>
            <div className="w-full grow self-start">
                <div>
                    {menuOptions.map((menu, index) => (
                        <NavElement key={index} menu={menu} index={index} />
                    ))}
                </div>
            </div>
            <div className="flex w-full flex-row self-end py-2 text-base">
                <div className="grow self-start">
                    &copy; <a href="https://www.azerion.com">Azerion</a>. All Rights Reserved
                </div>
                <div className="self-end">
                    <Profile />
                </div>
            </div>
        </div>
    );
};

export default FullPageMenu;
