const Download = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" role="icon" viewBox="0 0 24 24">
            <title>download</title>
            <path
                d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10s10-4.49 10-10S17.51 2 12 2zm-1 8V6h2v4h3l-4 4l-4-4h3zm6 7H7v-2h10v2z"
                fill="#fff"
            />
        </svg>
    );
};

export default Download;
