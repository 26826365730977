import { ReactNode } from 'react';

type BodyProps = {
    children: ReactNode;
};

const Body = ({ children }: BodyProps) => {
    return (
        <div className="my-4">
            <div className="w-full rounded-[5px] bg-lightpetrol p-8 text-lg">{children}</div>
        </div>
    );
};

export default Body;
