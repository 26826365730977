import { IAnalyticalWorkspace } from '@gooddata/sdk-backend-spi';
import { WorkspaceProvider } from '@gooddata/sdk-ui';
import React, { PropsWithChildren, useEffect, useState } from 'react';

import { getBackend } from './Backend';

export const selectWorkspace = (workspaces: IAnalyticalWorkspace[]): string => {
    const developmentWorkspace = process.env.REACT_APP_GOODDATA_DEFAULT_WORKSPACE as string;
    const hasAccessToDevelopment = workspaces.find(({ workspace }) => workspace === developmentWorkspace);
    return hasAccessToDevelopment ? developmentWorkspace : workspaces[0].workspace;
};

export const Workspace = ({ children }: PropsWithChildren<React.ReactNode>) => {
    const [workspace, setWorkspace] = useState('');

    const loadWorkspace = async () => {
        try {
            const result = await getBackend().workspaces().forCurrentUser().query();
            setWorkspace(selectWorkspace(result.items));
        } catch {
            setWorkspace('');
        }
    };

    useEffect(() => {
        loadWorkspace();
    }, [workspace]);

    return workspace ? <WorkspaceProvider workspace={workspace}>{children}</WorkspaceProvider> : null;
};
