import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { ApiResourceType } from '../../interfaces/ResourceTypes';
import { getContentTypeURL } from '../api';
import { initialiseState, setPending, setRejected, setSucceeded, State } from '../builders/state';

export const fetchResources = createAsyncThunk('resources/fetch', async () => {
    const response = await axios.get(getContentTypeURL('resources'));
    return response.data;
});

export interface ResourceState extends State {
    data: ApiResourceType;
}

export const initialState: ResourceState = initialiseState({ data: {} });

const resourceSlice = createSlice({
    extraReducers: (builder) => {
        builder
            .addCase(fetchResources.pending, setPending)
            .addCase(fetchResources.rejected, setRejected)
            .addCase(fetchResources.fulfilled, (state: ResourceState, action) => {
                state.data = action.payload;
                setSucceeded(state);
            });
    },
    initialState,
    name: 'resources',
    reducers: {},
});

export default resourceSlice.reducer;
