import DropdownFilter from '../../selectors/DropdownFilter';

export const CTR = 'CTR';
export const CVR = 'CVR';
export const VCR = 'VCR';
export const defaultOption = CTR;

export type MetricsFilterProps = {
    onChange: (value: string) => void;
};

export default function MetricsFilter({ onChange }: MetricsFilterProps) {
    return (
        <DropdownFilter
            dataTestId="metrics"
            defaultOption={defaultOption}
            options={[
                { label: CVR, value: CVR },
                { label: CTR, value: CTR },
                { label: VCR, value: VCR },
            ]}
            onChange={onChange}
        />
    );
}
