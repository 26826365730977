import { IAttribute, IAttributeFilter, newAttribute, newPositiveAttributeFilter } from '@gooddata/sdk-model';
import { useState } from 'react';

import AttributeFilter from './Attribute';

type CreativeFilterProps = {
    onApply: (filter: IAttributeFilter) => void;
};

export default function CreativeFilter(props: CreativeFilterProps) {
    const attribute: IAttribute = newAttribute('label.campaignmediadata_combined.creative_name.creativename');
    const defaultFilter = newPositiveAttributeFilter(attribute, { uris: [] });

    const [filter, setFilter] = useState<IAttributeFilter>(defaultFilter);

    const onApply = (filter: IAttributeFilter) => {
        props.onApply(filter);
        setFilter(filter);
    };

    return (
        <div data-testid="creative-filter">
            <AttributeFilter {...props} filter={filter} onApply={onApply} title="Creative" />
        </div>
    );
}
