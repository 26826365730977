import { PropsWithChildren, useContext } from 'react';

import { MetricContext, PixelTypeContext } from './Group';

type GroupItemProps = PropsWithChildren<React.ReactNode> & {
    metric?: string;
    pixelType: string;
};

export function GroupItem({ children, metric, pixelType }: GroupItemProps) {
    const metricContext = useContext(MetricContext);
    const pixelTypeContext = useContext(PixelTypeContext);
    const showForMetric = !metric || metricContext === metric;
    const showForPixelType = !pixelType || pixelTypeContext === pixelType;
    const display = showForMetric && showForPixelType ? 'block' : 'hidden';
    return <div className={display}>{children}</div>;
}

GroupItem.defaultProp = {
    metric: '',
    pixelType: '',
};
