import { BackendProvider } from '@gooddata/sdk-ui';
import React, { PropsWithChildren } from 'react';

import { getBackend } from './Backend';
import { isCypress } from './Cypress';
import { Theme } from './Theme';
import { Workspace } from './Workspace';

const Provider = ({ children }: PropsWithChildren<React.ReactNode>) => {
    if (isCypress()) {
        return <>{children}</>;
    }

    return (
        <BackendProvider backend={getBackend()}>
            <Workspace>
                <Theme>{children}</Theme>
            </Workspace>
        </BackendProvider>
    );
};

export default Provider;
