import LoadingIcon from '../../icons/Loading';

type LoadingProps = {
    className?: string;
};

const Loading = ({ className }: LoadingProps) => {
    return (
        <div className={`flex items-center justify-center ${className} w-full align-middle`}>
            <LoadingIcon className="h-8 w-12" />
        </div>
    );
};

export default Loading;
