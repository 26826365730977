import React from 'react';

import PageTitle from '../../components/display/PageTitle';

export default function Trends() {
    return (
        <React.Fragment>
            <PageTitle title="Trends" />
        </React.Fragment>
    );
}
