import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { ApiFAQType } from '../../interfaces/FAQTypes';
import { getContentTypeURL } from '../api';
import { initialiseState, setPending, setRejected, setSucceeded, State } from '../builders/state';

export const fetchFAQs = createAsyncThunk('faqs/fetch', async () => {
    const response = await axios.get(getContentTypeURL('faqs'));
    return response.data;
});

export interface FAQState extends State {
    data: ApiFAQType;
}

export const initialState: FAQState = initialiseState({ data: {} });

const faqSlice = createSlice({
    extraReducers: (builder) => {
        builder
            .addCase(fetchFAQs.pending, setPending)
            .addCase(fetchFAQs.rejected, setRejected)
            .addCase(fetchFAQs.fulfilled, (state: FAQState, action) => {
                state.data = action.payload;
                setSucceeded(state);
            });
    },
    initialState,
    name: 'faqs',
    reducers: {},
});

export default faqSlice.reducer;
