import { IInsightViewProps, InsightView } from '@gooddata/sdk-ui-ext';

import { isCypress } from './Cypress';
import Loading from './Loading';

const Insight = (props: IInsightViewProps) => {
    return (
        <div className="h-full w-full overflow-hidden" data-testid={props.insight}>
            {isCypress() ? props.insight : <InsightView {...props} />}
        </div>
    );
};

Insight.defaultProps = {
    LoadingComponent: Loading,
    config: {
        legend: { enabled: false },
        maxHeight: 400,
    },
    showTitle: true,
};

export default Insight;
