import React from 'react';

type SlideProps = {
    children: React.ReactNode;
    id: number;
    selectedKey: number;
};

export default function Slide({ children, id, selectedKey }: SlideProps) {
    const show = id === selectedKey;
    return (
        <div
            className={`flex h-full items-center justify-center overflow-hidden ${show ? 'visible' : 'hidden'}`}
            data-testid={`slide-${id}`}
        >
            {children}
        </div>
    );
}
