import { configureStore } from '@reduxjs/toolkit';

import faqsReducer from './slices/FAQ';
import homepageSlidesReducer from './slices/HomepageSlides';
import instagramImageReducer from './slices/InstagramImage';
import resourcesReducer from './slices/Resources';
import studioHubReducer from './slices/StudioHub';

export const store = configureStore({
    reducer: {
        faqs: faqsReducer,
        homepageSlides: homepageSlidesReducer,
        instagramImage: instagramImageReducer,
        resources: resourcesReducer,
        studioHub: studioHubReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
