import { ReactNode, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSearchParams } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';

import Modal from './Modal';

type CardProps = {
    id: number;
    title: string;
    children: ReactNode;
    modal: string;
};

export default function Card({ id, title, modal, children }: CardProps) {
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultVisible = parseInt(searchParams.get('resource') || '0', 10) === id;
    const [isModalVisible, setModalVisibility] = useState(defaultVisible);

    const modalHasContent = Boolean(modal);

    const showModalIfHasContent = () => {
        if (modalHasContent) {
            setModalVisibility(true);
            setSearchParams({ resource: String(id) });
        }
    };

    const hideModal = () => {
        setModalVisibility(false);
    };

    const cursorPointerClassIfModalHasContent = modalHasContent ? `cursor-pointer` : '';

    return (
        <>
            <div
                data-testid={`resource-${id}`}
                onClick={showModalIfHasContent}
                className={`h-min-52 flex basis-full flex-col rounded-[12px] bg-petrol text-lightbluegray ${cursorPointerClassIfModalHasContent}`}
            >
                <div className="w-fit px-6 pt-6 text-2xl font-bold">{title}</div>
                <div className="w-fit p-6 font-light">{children}</div>
            </div>
            <Modal visible={isModalVisible} hide={hideModal} title={title}>
                <ReactMarkdown linkTarget={'_blank'} rehypePlugins={[rehypeRaw]}>
                    {modal}
                </ReactMarkdown>
            </Modal>
        </>
    );
}
