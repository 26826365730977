import { IExportFunction } from '@gooddata/sdk-ui';
import { useCallback, useState } from 'react';

import Download from '../../icons/Download';

export type ExportDropdownProps = {
    onExport: IExportFunction;
};

const downloadExportURI = (uri: string) => {
    const anchor: HTMLAnchorElement = document.createElement('a');
    anchor.id = 'export-download-hidden-link';
    anchor.href = process.env.REACT_APP_GOODDATA_DOMAIN + uri;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
};

const ExportDropdownItem = (
    props: React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
    return (
        <li className="cursor-pointer bg-midpetrol px-2 hover:bg-lightpetrol" {...(props as object)}>
            {props.children}
        </li>
    );
};

const ExportDropdown = ({ onExport }: ExportDropdownProps) => {
    const FORMAT_XLSX = 'xlsx';
    const FORMAT_CSV = 'csv';

    const [expanded, setExpanded] = useState(false);

    const exportDataAs = useCallback(
        (format: typeof FORMAT_XLSX | typeof FORMAT_CSV) => async () => {
            const result = await onExport({ format });
            downloadExportURI(result.uri);
        },
        [onExport],
    );

    return (
        <div className="absolute right-0 top-0 z-20 flex flex-col">
            <label
                className="flex cursor-pointer justify-end"
                data-testid="download-icon"
                onClick={() => setExpanded(!expanded)}
            >
                <Download />
            </label>
            <ul className={`m-1 rounded-sm border border-gray-500 ${expanded ? 'inline' : 'hidden'}`}>
                <ExportDropdownItem onClick={exportDataAs(FORMAT_XLSX)}>XLSX</ExportDropdownItem>
                <ExportDropdownItem onClick={exportDataAs(FORMAT_CSV)}>CSV</ExportDropdownItem>
            </ul>
        </div>
    );
};

export default ExportDropdown;
