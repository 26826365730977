import TabOptions from './TabOptions';

type MediaDeliveryInsightsTabsProps = {
    onChange: (value: string) => void;
};

export default function MediaDeliveryInsightsTabs({ onChange }: MediaDeliveryInsightsTabsProps) {
    const options = {
        defaultOption: 'Performance',
        onChange: onChange,
        options: [
            { label: 'Performance', value: 'Performance' },
            { label: 'Creative', value: 'Creative' },
        ],
    };
    return (
        <div className="mb-8">
            <TabOptions {...options} />
        </div>
    );
}
