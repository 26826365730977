import React from 'react';
import { Link } from 'react-router-dom';

import { MenuOptionsItems } from '../../interfaces/NavigationTypes';
import LogoWithLink from '../display/LogoWithLink';
import NavElement from './NavElement';

type SidebarProps = {
    menuOptions: MenuOptionsItems;
};

export const getLogo = (height: string) => {
    return (
        <Link to="/">
            <img src="/images/Fusion_Logo_only.png" alt="Fusion" className={`${height} cursor-pointer`} />
        </Link>
    );
};
const Sidebar = ({ menuOptions }: SidebarProps) => {
    const topLinks = menuOptions.filter((entry) => !entry.isPositionBottom);
    const bottomLinks = menuOptions.filter((entry) => entry.isPositionBottom);

    return (
        <>
            <div className="flex h-screen w-full flex-col bg-petrol text-lightbluegray" data-testid="sidebar">
                <LogoWithLink className="h-32 pb-4 pl-16 pt-4" />
                <div>
                    {topLinks.map((menu, index) => (
                        <NavElement key={index} menu={menu} index={index} />
                    ))}
                </div>
                <div className="mb-12 mt-auto">
                    {bottomLinks.map((menu, index) => (
                        <NavElement key={index} menu={menu} index={index} />
                    ))}
                </div>
                <div className="mb-10 text-center text-xs">
                    &copy; <a href="https://www.azerion.com">Azerion</a>. All Rights Reserved
                </div>
            </div>
        </>
    );
};

export default Sidebar;
