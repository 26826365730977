import DropdownFilter from '../../selectors/DropdownFilter';

export const IMPRESSION = 'Impressions';
export const CLICK = 'Clicks';
export const CONVERSION = 'Conversions';
export const COMPLETED_VIEWS = 'Completed Views';
export const defaultOption = IMPRESSION;

export type PixelTypeFilterProps = {
    onChange: (value: string) => void;
};

export default function PixelTypeFilter({ onChange }: PixelTypeFilterProps) {
    return (
        <DropdownFilter
            dataTestId="pixel-type"
            options={[
                { label: IMPRESSION, value: IMPRESSION },
                { label: CLICK, value: CLICK },
                { label: CONVERSION, value: CONVERSION },
                { label: COMPLETED_VIEWS, value: COMPLETED_VIEWS },
            ]}
            defaultOption={defaultOption}
            onChange={onChange}
        />
    );
}
