import sdk from '@gooddata/api-client-bear';
import { NotAuthenticatedHandler } from '@gooddata/sdk-backend-spi';

sdk.config.setCustomDomain(process.env.REACT_APP_GOODDATA_DOMAIN as string);

export const loginWithRedirect: NotAuthenticatedHandler = () => {
    sdk.user.initiateSamlSso(window.location.origin);
};

export const logout = () => {
    return sdk.user.logout();
};
