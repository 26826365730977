import {
    DateFilterGranularity,
    DateGranularity,
    IAbsoluteDateFilterPreset,
    IDateFilter,
    IRelativeDateFilterPreset,
    newAbsoluteDateFilter,
    newAllTimeFilter,
    newRelativeDateFilter,
    ObjRef,
} from '@gooddata/sdk-model';
import { DateFilter } from '@gooddata/sdk-ui-filters';
import { DateFilterOption, defaultDateFilterOptions } from '@gooddata/sdk-ui-filters/esm/DateFilter';
import { useState } from 'react';

export const availableGranularities = [
    DateGranularity.date,
    DateGranularity.month,
    DateGranularity.quarter,
    DateGranularity.year,
] as DateFilterGranularity[];

export type DateRangeProps = {
    definition: string | ObjRef;
    customFilterName: string;
    default: DateFilterOption;
    onApply: (filter: IDateFilter) => void;
};

export default function DateRange(props: DateRangeProps) {
    const [filter, setFilter] = useState({ excludeCurrentPeriod: false, selectedFilterOption: props.default });

    const relativeFilter = (option: DateFilterOption) => {
        const { granularity, from, to } = option as IRelativeDateFilterPreset;
        return newRelativeDateFilter(props.definition, granularity, from, to);
    };

    const absoluteFilter = (option: DateFilterOption) => {
        const { from, to } = option as IAbsoluteDateFilterPreset;
        return newAbsoluteDateFilter(props.definition, from, to);
    };

    const allTimeFilter = () => {
        return newAllTimeFilter(props.definition);
    };

    const newFilter = (option: DateFilterOption): IDateFilter => {
        switch (option.type) {
            case 'absoluteForm':
            case 'absolutePreset':
                return absoluteFilter(option);
            case 'relativeForm':
            case 'relativePreset':
                return relativeFilter(option);
            default:
                return allTimeFilter();
        }
    };

    const onApply = (selectedFilterOption: DateFilterOption, excludeCurrentPeriod: boolean) => {
        props.onApply(newFilter(selectedFilterOption));
        setFilter({ excludeCurrentPeriod, selectedFilterOption });
    };

    return (
        <div className="date-filter-wrapper flex h-[1.6875rem] w-[6.25rem] cursor-pointer justify-center rounded-sm border border-darkgray bg-midlightpetrol pr-4 pt-[0.38rem] text-xs font-thin text-slate-400/100 hover:bg-lightpetrol">
            <DateFilter
                {...filter}
                filterOptions={defaultDateFilterOptions}
                availableGranularities={availableGranularities}
                customFilterName={props.customFilterName}
                dateFilterMode="active"
                onApply={onApply}
            />
        </div>
    );
}

DateRange.defaultProps = {
    default: {
        from: 0,
        granularity: 'GDC.time.quarter',
        localIdentifier: 'THIS_QUARTER',
        name: '',
        to: 0,
        type: 'relativePreset',
        visible: true,
    } as DateFilterOption,
};
