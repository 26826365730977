import Profile from './user/Profile';

const TopBar = () => {
    return (
        <nav className="mt-2 flex h-10 w-full items-center justify-end pr-8" data-testid="topbar">
            <Profile />
        </nav>
    );
};

export default TopBar;
