import './index.css';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import Auth0Provider from './services/auth0/Provider';
import GoodDataProvider from './services/gooddata/Provider';
import { store } from './store/store';

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
});

const container = document.getElementById('root');
ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Auth0Provider>
                <GoodDataProvider>
                    <App />
                </GoodDataProvider>
            </Auth0Provider>
        </Provider>
    </React.StrictMode>,
    container,
);
