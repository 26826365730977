import React from 'react';

export type TabElement = {
    label: string;
    content: React.ReactNode;
};

type TabGroupProps = {
    tabs: TabElement[];
    selected: string;
};

export default function TabGroup({ tabs, selected }: TabGroupProps) {
    return (
        <>
            {tabs.map((tab, index) => {
                return (
                    <div
                        key={`${index}-${tab.label}`}
                        data-testid={`${tab.label}-tab`}
                        className={`${selected === tab.label ? 'block' : 'hidden'}`}
                    >
                        {tab.content}
                    </div>
                );
            })}
        </>
    );
}
