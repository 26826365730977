import { ReactNode } from 'react';

import Collapse from '../../../icons/Collapse';
import Expand from '../../../icons/Expand';

type TitleProps = {
    isOpen: boolean;
    onClick: React.MouseEventHandler<HTMLDivElement>;
    children: ReactNode;
};

const Title = ({ isOpen, onClick, children }: TitleProps) => {
    const extraStyles = isOpen ? 'text-petrol bg-mintygreen' : 'bg-petrol';
    const icon = isOpen ? <Collapse /> : <Expand />;

    return (
        <div className="my-4">
            <div
                className={`flex h-20 cursor-pointer items-center rounded-[5px] p-8 text-xl font-medium ${extraStyles}`}
                onClick={onClick as React.MouseEventHandler<HTMLDivElement>}
            >
                {children}
                <div className="ml-auto">{icon}</div>
            </div>
        </div>
    );
};

export default Title;
