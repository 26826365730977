import React, { useState } from 'react';

import Gallery from '../components/display/Gallery';
import PageTitle from '../components/display/PageTitle';
import StudioHubElement from '../components/display/StudioHubElement';
import DropdownStudioHubFilter from '../components/selectors/DropdownFilter';
import { StudioHubDataType, StudioHubElementsType } from '../interfaces/StudioHubTypes';

export type StudioHubProps = {
    data: StudioHubDataType;
};

const all = 'All';

const channelOptions = [
    { label: all, value: all },
    { label: 'Desktop', value: 'Desktop' },
    { label: 'Mobile', value: 'Mobile' },
    { label: 'OLV', value: 'OLV' },
    { label: 'CTV', value: 'CTV' },
];

const formatOptions = [
    { label: all, value: all },
    { label: 'Engage', value: 'Engage' },
    { label: 'QR Code CTV', value: 'QR Code CTV' },
    { label: 'Standard Banners', value: 'Standard Banners' },
    { label: 'Interstitial', value: 'Interstitial' },
    { label: 'Billboard', value: 'Billboard' },
    { label: 'Interscroller', value: 'Interscroller' },
];

const featureOptions = [
    { label: all, value: all },
    { label: 'Carousels', value: 'Carousels' },
    { label: 'Display', value: 'Display' },
    { label: 'Store Locator', value: 'Store Locator' },
    { label: 'Hotspots', value: 'Hotspots' },
];

export default function StudioHub({ data }: StudioHubProps) {
    const [channelSelected, setChannelSelected] = useState(all);
    const [formatSelected, setFormatSelected] = useState(all);
    const [featureSelected, setFeatureSelected] = useState(all);

    const renderChannelFilter = () => (
        <DropdownStudioHubFilter
            defaultOption={all}
            options={channelOptions}
            onChange={setChannelSelected}
            dataTestId="channel"
            label="Channel"
        />
    );

    const renderFormatFilter = () => (
        <DropdownStudioHubFilter
            defaultOption={all}
            options={formatOptions}
            onChange={setFormatSelected}
            dataTestId="format"
            label="Format"
        />
    );

    const renderFeatureFilter = () => (
        <DropdownStudioHubFilter
            defaultOption={all}
            options={featureOptions}
            onChange={setFeatureSelected}
            dataTestId="feature"
            label="Feature"
        />
    );

    const shouldShow = (row: StudioHubElementsType): boolean => {
        const isFilterOk = (attributes: string[], selected: string): boolean => {
            const isAll = selected === all;
            const isSelected = attributes.includes(selected);
            return isAll || isSelected;
        };

        const isChannel = isFilterOk(row.attributes.Channel, channelSelected);
        const isFormat = isFilterOk(row.attributes.Format, formatSelected);
        const isFeature = isFilterOk(row.attributes.Feature, featureSelected);
        return isChannel && isFormat && isFeature;
    };

    return (
        <React.Fragment>
            <PageTitle title="Azerion Ad Studio Hub" />
            <h2 className="text-xl">See our ads in action.</h2>
            <div className="font-light text-lightbluegray">
                Creative that resonates with the target audience helps brands to stand out in the market. From gaming to
                audio to performance media, there’s a format for every function. Azerion can help you build powerful,
                immersive experiences that engage your audiences and foster a strong emotional connection with your
                brand.
            </div>
            <div className="my-10 flex flex-col gap-4 md:flex-row">
                {renderChannelFilter()}
                {renderFormatFilter()}
                {renderFeatureFilter()}
            </div>
            <Gallery total={data?.data?.length as number}>
                {data?.data?.filter(shouldShow).map((row: StudioHubElementsType, index: number) => (
                    <StudioHubElement row={row} key={index} />
                ))}
            </Gallery>
        </React.Fragment>
    );
}
