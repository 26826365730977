const Collapse = () => {
    return (
        <svg role="icon" xmlns="http://www.w3.org/2000/svg" width="23.511" height="5.23" viewBox="0 0 23.511 5.23">
            <title>collapse</title>
            <path
                id="Path_30575"
                data-name="Path 30575"
                d="M15.389-5.181h8.952V.049H.83v-5.23H15.389Z"
                transform="translate(-0.83 5.181)"
                fill="#0f2030"
            />
        </svg>
    );
};

export default Collapse;
