import React from 'react';

import PageTitle from '../components/display/PageTitle';
import ResourcesCards from '../components/ResourcesCards';
import { ApiResourceType } from '../interfaces/ResourceTypes';

export type ResourcesProps = {
    resources: ApiResourceType;
};

export default function Resources({ resources }: ResourcesProps) {
    return (
        <React.Fragment>
            <PageTitle title="Resources" />
            <div className="mt-4 flex flex-col gap-6 lg:w-fit lg:flex-row">
                <ResourcesCards resources={resources} />
            </div>
        </React.Fragment>
    );
}
