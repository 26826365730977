import React from 'react';

import PageTitle from '../components/display/PageTitle';
import InsightWithExport from '../services/gooddata/InsightWithExport';
import Loading from '../services/gooddata/Loading';

export default function AudienceManager() {
    const loading = () => <Loading className={'h-[25rem]'} />;
    return (
        <React.Fragment>
            <PageTitle title="Audience Manager" />
            <InsightWithExport insight="aamMKLT6vHHN" LoadingComponent={loading} />
        </React.Fragment>
    );
}
