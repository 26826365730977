import { createContext, PropsWithChildren, ReactNode, useState } from 'react';

import MetricsFilter, { defaultOption as defaultMetric } from './MetricsFilter';
import PixelTypeFilter, { defaultOption as defaultPixelType } from './PixelTypeFilter';

export const MetricContext = createContext(defaultMetric);
export const PixelTypeContext = createContext(defaultPixelType);

export default function Group({ children }: PropsWithChildren<ReactNode>) {
    const [metric, setMetric] = useState(defaultMetric);
    const [pixelType, setPixelType] = useState(defaultPixelType);

    return (
        <div>
            <div className="mb-8 flex gap-4">
                <PixelTypeFilter onChange={setPixelType} />
                <MetricsFilter onChange={setMetric} />
            </div>
            <div className="mb-8 grid grid-cols-2 gap-4 text-center">
                <MetricContext.Provider value={metric}>
                    <PixelTypeContext.Provider value={pixelType}>{children}</PixelTypeContext.Provider>
                </MetricContext.Provider>
            </div>
        </div>
    );
}
