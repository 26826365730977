import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { ApiHomepageSlideShowType } from '../../interfaces/HomepageSlideShowTypes';
import { getContentTypeURL } from '../api';
import { initialiseState, setPending, setRejected, setSucceeded, State } from '../builders/state';

export const fetchHomepageSlides = createAsyncThunk('homepage-slides/fetch', async () => {
    const response = await axios.get(getContentTypeURL('homepage-slide-show-items'));
    return response.data;
});

export interface HomepageSlidesState extends State {
    data: ApiHomepageSlideShowType;
}

export const initialState: HomepageSlidesState = initialiseState({ data: {} });

const homepageSlices = createSlice({
    extraReducers: (builder) => {
        builder
            .addCase(fetchHomepageSlides.pending, setPending)
            .addCase(fetchHomepageSlides.rejected, setRejected)
            .addCase(fetchHomepageSlides.fulfilled, (state: HomepageSlidesState, action) => {
                state.data = action.payload;
                setSucceeded(state);
            });
    },
    initialState,
    name: 'homepageSlides',
    reducers: {},
});

export default homepageSlices.reducer;
