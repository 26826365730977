import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { ApiResourceType, ResourceType } from '../interfaces/ResourceTypes';
import Card from './display/Card';

export type ResourcesCardsProps = {
    resources: ApiResourceType;
};

export default function ResourcesCards({ resources }: ResourcesCardsProps) {
    return (
        <React.Fragment>
            {resources?.data?.map((row: ResourceType) => (
                <Card
                    key={row.attributes.Order}
                    id={row.id}
                    title={row.attributes.Title}
                    modal={row.attributes.FullText}
                >
                    <ReactMarkdown linkTarget={'_blank'} rehypePlugins={[rehypeRaw]}>
                        {row.attributes.Summary}
                    </ReactMarkdown>
                </Card>
            ))}
        </React.Fragment>
    );
}
