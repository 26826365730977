import React, { useEffect } from 'react';

import { HomeProps } from '../../pages/Home';
import { IDLE } from '../builders/state';
import { useAppDispatch, useAppSelector } from '../hooks';
import { fetchHomepageSlides } from '../slices/HomepageSlides';
import { fetchInstagramImage } from '../slices/InstagramImage';
import { fetchResources } from '../slices/Resources';

const withHomeContainer = (Home: React.FC<HomeProps>) => {
    const HomeContainer: React.FC = (props) => {
        const dispatch = useAppDispatch();
        const slides = useAppSelector((state) => state.homepageSlides.data);
        const statusSlides = useAppSelector((state) => state.homepageSlides.status);

        const instagramImage = useAppSelector((state) => state.instagramImage.data);
        const statusInstagramImage = useAppSelector((state) => state.instagramImage.status);

        const resources = useAppSelector((state) => state.resources.data);
        const statusResources = useAppSelector((state) => state.resources.status);

        useEffect(() => {
            if (statusSlides === IDLE) {
                dispatch(fetchHomepageSlides());
            }
            if (statusInstagramImage === IDLE) {
                dispatch(fetchInstagramImage());
            }
            if (statusResources === IDLE) {
                dispatch(fetchResources());
            }
        }, [statusSlides, statusInstagramImage, statusResources, dispatch]);

        return <Home slides={slides} instagramImage={instagramImage} resources={resources} {...props} />;
    };

    return HomeContainer;
};

export default withHomeContainer;
