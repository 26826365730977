import { NavLink } from 'react-router-dom';

import { useProvider } from '../../services/auth0/State';
import { logout as logoutGoodData } from '../../services/gooddata/Authentication';

const Profile = () => {
    const { user, isAuthenticated, logout: logoutAuth0 } = useProvider();

    if (isAuthenticated && user) {
        return (
            <div className="flex gap-2" data-testid="profile">
                <NavLink to="/settings" key="profile-user-name-to-settings-id" className="text-white">
                    {user.name}
                </NavLink>
                <button
                    className="text-fluorgreen hover:underline"
                    onClick={async () => {
                        await logoutGoodData();
                        logoutAuth0({ returnTo: window.location.origin });
                    }}
                >
                    Log out
                </button>
            </div>
        );
    }

    return <></>;
};

export default Profile;
