import '@gooddata/sdk-ui-charts/styles/css/main.css';
import '@gooddata/sdk-ui-kit/styles/css/main.css';
import '@gooddata/sdk-ui-pivot/styles/css/main.css';

import { ITheme } from '@gooddata/sdk-model';
import { ThemeProvider } from '@gooddata/sdk-ui-theme-provider';
import React, { PropsWithChildren } from 'react';

export const Theme = ({ children }: PropsWithChildren<React.ReactNode>) => {
    const flourGreen = '#04FA6E';
    const lightbluegray = '#B0CAD1';
    const lightgray = '#D9D9D9';
    const petrol = '#0E2030';

    const customTheme: ITheme = {
        chart: {
            axisColor: lightbluegray,
            axisLabelColor: lightgray,
            axisValueColor: lightbluegray,
            backgroundColor: petrol,
            gridColor: lightbluegray,
        },
        kpi: {
            primaryMeasureColor: flourGreen,
            secondaryInfoColor: lightbluegray,
        },
        palette: {
            complementary: {
                c0: petrol,
                c9: lightbluegray,
            },
        },
        typography: {
            font: 'url(/fonts/Roboto-Light.ttf);',
            fontBold: 'url(/fonts/Roboto-Regular.ttf);',
        },
    };
    return <ThemeProvider theme={customTheme}>{children}</ThemeProvider>;
};
