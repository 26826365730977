type DotProps = {
    selected: boolean;
    index: number;
};

export default function Dot({ selected, index }: DotProps) {
    return (
        <div
            className={`h-4 w-4 rounded-full border-2 border-white ${selected && 'bg-white'} mx-2`}
            data-testid={`dot-${index}`}
        >
            <svg
                className="animate-pulse px-1"
                width="20px"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="circle"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                role="icon"
            >
                <path fill="none" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z" />
            </svg>
        </div>
    );
}
