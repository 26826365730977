import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../icons/Logo';

function LogoWithLink({
    className,
}: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) {
    return (
        <Link to="/" data-testid="logo-with-link">
            <Logo className={className} />
        </Link>
    );
}

export default LogoWithLink;
