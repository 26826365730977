import { ReactNode } from 'react';

export type ModalProps = {
    title: string;
    visible: boolean;
    hide: () => void;
    children: ReactNode;
};

export default function Modal({ title, visible, hide, children }: ModalProps) {
    const visibility = visible ? 'fixed' : 'hidden';
    return (
        <div
            tabIndex={-1}
            className={`${visibility} left-0 right-0 top-0 z-50 h-full w-full overflow-y-auto overflow-x-hidden bg-gray-600 bg-opacity-70 p-6 md:inset-0`}
        >
            <div className="flex h-full items-center justify-center">
                <div className="relative max-h-full w-full max-w-3xl">
                    <div className="relative rounded-lg bg-petrol shadow">
                        <div className="flex items-start justify-between rounded-t border-b border-gray-600 p-6">
                            <h3 className="text-xl font-semibold text-white">{title}</h3>
                            <button
                                type="button"
                                className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-600 hover:text-white"
                                onClick={hide}
                            >
                                <svg
                                    className="h-5 w-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                        <div className="h-[80vh] space-y-6 overflow-y-auto p-6 font-light text-lightbluegray">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
