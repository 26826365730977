import { idRef, newAttribute } from '@gooddata/sdk-model';

export const filterDefinition = {
    Date: {
        DdMmYyyy: newAttribute('campaignmediadatacombineddate.date.day.uk.dd_mm_yyyy'),
        DdMmYyyy_1: newAttribute('campaignmediadatacombineddate.date.day.eu.dd_mm_yyyy'),
        Long: newAttribute('campaignmediadatacombineddate.date.day.us.long'),
        MDYy: newAttribute('campaignmediadatacombineddate.date.day.us.m_d_yy'),
        MmDdYyyy: newAttribute('campaignmediadatacombineddate.date.day.us.mm_dd_yyyy'),
        YyyyMmDd: newAttribute('campaignmediadatacombineddate.date.day.yyyy_mm_dd'),
        identifier: 'campaignmediadatacombineddate.date',
        ref: idRef('campaignmediadatacombineddate.date', 'attribute'),
    },
    DayOfMonth: {
        Default: newAttribute('campaignmediadatacombineddate.day.in.month.default'),
        identifier: 'campaignmediadatacombineddate.day.in.month',
        ref: idRef('campaignmediadatacombineddate.day.in.month', 'attribute'),
    },
    DayOfQuarter: {
        Default: newAttribute('campaignmediadatacombineddate.day.in.quarter.default'),
        identifier: 'campaignmediadatacombineddate.day.in.quarter',
        ref: idRef('campaignmediadatacombineddate.day.in.quarter', 'attribute'),
    },
    DayOfWeekMonSun: {
        Long: newAttribute('campaignmediadatacombineddate.day.in.euweek.long'),
        Number: newAttribute('campaignmediadatacombineddate.day.in.euweek.number'),
        Short: newAttribute('campaignmediadatacombineddate.day.in.euweek.short'),
        identifier: 'campaignmediadatacombineddate.day.in.euweek',
        ref: idRef('campaignmediadatacombineddate.day.in.euweek', 'attribute'),
    },
    DayOfWeekSunSat: {
        Long: newAttribute('campaignmediadatacombineddate.day.in.week.long'),
        Number: newAttribute('campaignmediadatacombineddate.day.in.week.number'),
        Short: newAttribute('campaignmediadatacombineddate.day.in.week.short'),
        identifier: 'campaignmediadatacombineddate.day.in.week',
        ref: idRef('campaignmediadatacombineddate.day.in.week', 'attribute'),
    },
    DayOfYear: {
        Default: newAttribute('campaignmediadatacombineddate.day.in.year.default'),
        identifier: 'campaignmediadatacombineddate.day.in.year',
        ref: idRef('campaignmediadatacombineddate.day.in.year', 'attribute'),
    },
    EUWeekQuarter: {
        WeekQuarter: newAttribute('campaignmediadatacombineddate.quarter.for.euweek.number'),
        identifier: 'campaignmediadatacombineddate.quarter.for.euweek',
        ref: idRef('campaignmediadatacombineddate.quarter.for.euweek', 'attribute'),
    },
    EUWeekYear: {
        WeekYear: newAttribute('campaignmediadatacombineddate.year.for.euweek.number'),
        identifier: 'campaignmediadatacombineddate.year.for.euweek',
        ref: idRef('campaignmediadatacombineddate.year.for.euweek', 'attribute'),
    },
    Month: {
        Long: newAttribute('campaignmediadatacombineddate.month.in.year.long'),
        MQ: newAttribute('campaignmediadatacombineddate.month.in.year.m_q'),
        Number: newAttribute('campaignmediadatacombineddate.month.in.year.number'),
        Short: newAttribute('campaignmediadatacombineddate.month.in.year.short'),
        identifier: 'campaignmediadatacombineddate.month.in.year',
        ref: idRef('campaignmediadatacombineddate.month.in.year', 'attribute'),
    },
    MonthOfQuarter: {
        Number: newAttribute('campaignmediadatacombineddate.month.in.quarter.number'),
        identifier: 'campaignmediadatacombineddate.month.in.quarter',
        ref: idRef('campaignmediadatacombineddate.month.in.quarter', 'attribute'),
    },
    MonthYear: {
        Long: newAttribute('campaignmediadatacombineddate.month.long'),
        Number: newAttribute('campaignmediadatacombineddate.month.number'),
        Short: newAttribute('campaignmediadatacombineddate.month.short'),
        identifier: 'campaignmediadatacombineddate.month',
        ref: idRef('campaignmediadatacombineddate.month', 'attribute'),
    },
    Quarter: {
        Default: newAttribute('campaignmediadatacombineddate.quarter.in.year.default'),
        identifier: 'campaignmediadatacombineddate.quarter.in.year',
        ref: idRef('campaignmediadatacombineddate.quarter.in.year', 'attribute'),
    },
    QuarterYear: {
        USShort: newAttribute('campaignmediadatacombineddate.quarter.short_us'),
        identifier: 'campaignmediadatacombineddate.quarter',
        ref: idRef('campaignmediadatacombineddate.quarter', 'attribute'),
    },
    USWeekQuarter: {
        WeekQuarter: newAttribute('campaignmediadatacombineddate.quarter.for.week.number'),
        identifier: 'campaignmediadatacombineddate.quarter.for.week',
        ref: idRef('campaignmediadatacombineddate.quarter.for.week', 'attribute'),
    },
    USWeekYear: {
        WeekYear: newAttribute('campaignmediadatacombineddate.year.for.week.number'),
        identifier: 'campaignmediadatacombineddate.year.for.week',
        ref: idRef('campaignmediadatacombineddate.year.for.week', 'attribute'),
    },
    WeekMonSun: {
        NumberEU: newAttribute('campaignmediadatacombineddate.euweek.in.year.number_eu'),
        identifier: 'campaignmediadatacombineddate.euweek.in.year',
        ref: idRef('campaignmediadatacombineddate.euweek.in.year', 'attribute'),
    },
    WeekMonSunOfQtr: {
        NumberEU: newAttribute('campaignmediadatacombineddate.euweek.in.quarter.number_eu'),
        identifier: 'campaignmediadatacombineddate.euweek.in.quarter',
        ref: idRef('campaignmediadatacombineddate.euweek.in.quarter', 'attribute'),
    },
    WeekMonSunYear: {
        FromTo: newAttribute('campaignmediadatacombineddate.euweek.from_to'),
        WeekNrYear: newAttribute('campaignmediadatacombineddate.euweek.wk_year'),
        WeekStarting: newAttribute('campaignmediadatacombineddate.euweek.starting'),
        identifier: 'campaignmediadatacombineddate.euweek',
        ref: idRef('campaignmediadatacombineddate.euweek', 'attribute'),
    },
    WeekSunSat: {
        NumberUS: newAttribute('campaignmediadatacombineddate.week.in.year.number_us'),
        identifier: 'campaignmediadatacombineddate.week.in.year',
        ref: idRef('campaignmediadatacombineddate.week.in.year', 'attribute'),
    },
    WeekSunSatOfQtr: {
        NumberUS: newAttribute('campaignmediadatacombineddate.week.in.quarter.number_us'),
        identifier: 'campaignmediadatacombineddate.week.in.quarter',
        ref: idRef('campaignmediadatacombineddate.week.in.quarter', 'attribute'),
    },
    WeekSunSatYear: {
        FromTo: newAttribute('campaignmediadatacombineddate.week.from_to'),
        WeekNrYear: newAttribute('campaignmediadatacombineddate.week.wk_year'),
        WeekNrYear_1: newAttribute('campaignmediadatacombineddate.week.wk_year_cont'),
        WeekStarting: newAttribute('campaignmediadatacombineddate.week.starting'),
        WkQtrYear: newAttribute('campaignmediadatacombineddate.week.wk_qtr_year_cont'),
        WkQtrYear_1: newAttribute('campaignmediadatacombineddate.week.wk_qtr_year'),
        identifier: 'campaignmediadatacombineddate.week',
        ref: idRef('campaignmediadatacombineddate.week', 'attribute'),
    },
    Year: {
        Default: newAttribute('campaignmediadatacombineddate.year.default'),
        identifier: 'campaignmediadatacombineddate.year',
        ref: idRef('campaignmediadatacombineddate.year', 'attribute'),
    },
    identifier: 'campaignmediadatacombineddate.dataset.dt',
    ref: idRef('campaignmediadatacombineddate.dataset.dt', 'dataSet'),
};
