import { StudioHubElementsType } from '../../interfaces/StudioHubTypes';

type StudioHubElementProps = {
    row: StudioHubElementsType;
};

export default function StudioHubElement({ row }: StudioHubElementProps) {
    const getImage = (row: StudioHubElementsType): string => {
        const image = row.attributes.Preview.data.attributes;
        return image.formats.small?.url ? image.formats.small.url : image.url;
    };

    const getURL = (row: StudioHubElementsType): string => row.attributes.URL;

    const getName = (row: StudioHubElementsType): string => row.attributes.Name;

    return (
        <div className="relative xl:h-[15.6rem] xl:w-[15.6rem]" data-testid="studio-hub-element">
            <div className="duration-600 darkpetrol group absolute flex h-full w-full flex-col justify-between gap-5 pl-4 pt-16 align-middle transition-opacity">
                <div className="absolute inset-0 bg-platinumgray opacity-0 transition-opacity group-hover:opacity-90" />
                <div
                    data-testid="studio-hub-element-name"
                    className="relative z-10 flex-grow pr-4 text-black opacity-0 transition-opacity group-hover:opacity-100"
                >
                    {getName(row)}
                </div>
                <a
                    href={getURL(row)}
                    target="_blank"
                    rel="noreferrer"
                    data-testid="studio-hub-element-link"
                    className="relative z-10 flex justify-end pb-4 pr-2 text-black opacity-0 transition-opacity group-hover:opacity-100"
                >
                    +More Details
                </a>
            </div>
            <img data-testid={getImage(row)} src={getImage(row)} alt={getName(row)} className="w-full cursor-pointer" />
        </div>
    );
}
