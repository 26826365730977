import React from 'react';

import PageTitle from '../components/display/PageTitle';
import { useProvider } from '../services/auth0/State';

export default function Settings() {
    const { user } = useProvider();

    return (
        <React.Fragment>
            <PageTitle title="Settings" />
            <div className="flex gap-48 ">
                <p className="border-b-2 border-b-fluorgreen text-fluorgreen">Account Settings</p>
            </div>
            <form className="mt-9 h-[26rem] items-center justify-center gap-7 rounded-lg bg-midpetrol p-3 lg:flex lg:flex-wrap">
                <div className="flex flex-col gap-2 lg:w-96">
                    <label className="text-lg text-slate-400 " id="name">
                        Full Name
                    </label>
                    <input
                        data-testid="settings-form-name-field"
                        className="h-8 cursor-default rounded-md bg-lightpetrol p-2"
                        value={user ? user.name : ''}
                        readOnly
                        aria-labelledby="name"
                    />
                </div>
                <div className="flex flex-col gap-2 lg:w-96">
                    <label className="text-lg text-slate-400" id="email">
                        Email
                    </label>
                    <input
                        aria-labelledby="email"
                        readOnly
                        className="h-8 cursor-default rounded-md bg-lightpetrol p-2"
                        value={user ? user.email : ''}
                    />
                </div>
            </form>
        </React.Fragment>
    );
}
