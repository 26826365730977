import { PropsWithChildren, ReactNode } from 'react';

import Loading from '../../components/display/Loading';
import { useProvider } from './State';

const Authentication = ({ children }: PropsWithChildren<ReactNode>) => {
    const { isLoading, isAuthenticated, loginWithRedirect } = useProvider();

    if (isLoading) {
        return <Loading />;
    }

    if (!isAuthenticated) {
        loginWithRedirect();
        return <Loading />;
    }

    return <>{children}</>;
};

export default Authentication;
