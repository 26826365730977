import { IAttributeFilter, IDateFilter } from '@gooddata/sdk-model';
import React, { useCallback, useMemo, useState } from 'react';

import Group from '../../components/display/groupFilter/Group';
import { GroupItem } from '../../components/display/groupFilter/GroupItem';
import { CTR, CVR, VCR } from '../../components/display/groupFilter/MetricsFilter';
import { CLICK, COMPLETED_VIEWS, CONVERSION, IMPRESSION } from '../../components/display/groupFilter/PixelTypeFilter';
import PageTitle from '../../components/display/PageTitle';
import TabGroup, { TabElement } from '../../components/display/TabGroup';
import MediaDeliveryChartsTabSelector from '../../components/selectors/MediaDeliveryInsightsTabs';
import AdvertiserFilter from '../../services/gooddata/filters/Advertiser';
import CampaignFilter from '../../services/gooddata/filters/Campaign';
import CampaignMediaDataCombined from '../../services/gooddata/filters/CampaignMediaDataCombined';
import CreativeFilter from '../../services/gooddata/filters/Creative';
import DataTableSourceFilter from '../../services/gooddata/filters/DataTableSource';
import Insight from '../../services/gooddata/InsightView';
import InsightWithExport from '../../services/gooddata/InsightWithExport';
import Loading from '../../services/gooddata/Loading';

const headerChartsLoadingComponent = () => <Loading className={'h-[9.578rem]'} />;
const baseChartsLoadingComponent = () => <Loading className={'h-[25rem]'} />;

export default function MediaDelivery() {
    const [advertiserFilters, advertiserSetFilters] = useState<IAttributeFilter[]>([]);
    const [campaignFilters, campaignSetFilters] = useState<IAttributeFilter[]>([]);
    const [dateRangeFilters, dateRangeSetFilers] = useState<IDateFilter[]>([]);
    const [dataTableSourceFilters, dataTableSourceSetFilters] = useState<IAttributeFilter[]>([]);
    const [creativeFilters, creativeSetFilters] = useState<IAttributeFilter[]>([]);
    const [groupChartSelector, setGroupChartSelector] = useState('Performance');

    const insightsFilters = useMemo(() => {
        return [
            ...advertiserFilters,
            ...campaignFilters,
            ...dataTableSourceFilters,
            ...dateRangeFilters,
            ...creativeFilters,
        ];
    }, [advertiserFilters, campaignFilters, dataTableSourceFilters, dateRangeFilters, creativeFilters]);

    const chartInsightById = useCallback(
        (insightId: string) => {
            return (
                <InsightWithExport
                    insight={insightId}
                    filters={insightsFilters}
                    LoadingComponent={baseChartsLoadingComponent}
                />
            );
        },
        [insightsFilters],
    );

    const renderPerformanceTab = useMemo(() => {
        const headlineInsightById = (insightId: string) => {
            return (
                <Insight
                    insight={insightId}
                    filters={insightsFilters}
                    LoadingComponent={headerChartsLoadingComponent}
                />
            );
        };

        return (
            <>
                <div className="my-8 flex h-[66.031rem] flex-col gap-6 border-b border-lightbluegray pb-4 text-center md:h-[21.711rem]">
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
                        {headlineInsightById('aavxFp4XW7Cy')}
                        {headlineInsightById('aabxKZFxZNBQ')}
                        {headlineInsightById('aawxFaQEW7xR')}
                    </div>
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
                        {headlineInsightById('aaVxxX0wqpyY')}
                        {headlineInsightById('aaHxyI7kqpDk')}
                        {headlineInsightById('aaGxyI7kqpDk')}
                    </div>
                </div>
                <Group>
                    <div className="col-span-2">
                        <GroupItem metric={CTR} pixelType={IMPRESSION}>
                            {chartInsightById('aabxwhrRQ6sy')}
                        </GroupItem>
                        <GroupItem metric={CVR} pixelType={IMPRESSION}>
                            {chartInsightById('aaFxoB7eFZ2D')}
                        </GroupItem>
                        <GroupItem metric={CTR} pixelType={CLICK}>
                            {chartInsightById('aadxCqJ5tG2v')}
                        </GroupItem>
                        <GroupItem metric={CVR} pixelType={CLICK}>
                            {chartInsightById('aafxCeeztGMZ')}
                        </GroupItem>
                        <GroupItem metric={CTR} pixelType={CONVERSION}>
                            {chartInsightById('aahxvDMgqdYz')}
                        </GroupItem>
                        <GroupItem metric={CVR} pixelType={CONVERSION}>
                            {chartInsightById('aahxBWLfVYwo')}
                        </GroupItem>
                        <GroupItem metric={VCR} pixelType={IMPRESSION}>
                            {chartInsightById('aaEucoXImL8K')}
                        </GroupItem>
                        <GroupItem metric={VCR} pixelType={CLICK}>
                            {chartInsightById('aaculTKkrIUR')}
                        </GroupItem>
                        <GroupItem metric={VCR} pixelType={CONVERSION}>
                            {chartInsightById('aagulmLorgKM')}
                        </GroupItem>
                        <GroupItem metric={CTR} pixelType={COMPLETED_VIEWS}>
                            {chartInsightById('aaiumvhZrJqy')}
                        </GroupItem>
                        <GroupItem metric={CVR} pixelType={COMPLETED_VIEWS}>
                            {chartInsightById('aa7t7PJflD2n')}
                        </GroupItem>
                        <GroupItem metric={VCR} pixelType={COMPLETED_VIEWS}>
                            {chartInsightById('aaFuiW7ymdBN')}
                        </GroupItem>
                    </div>
                    <div>
                        <GroupItem metric={CTR} pixelType={IMPRESSION}>
                            {chartInsightById('aafxC1IQtNgG')}
                        </GroupItem>
                        <GroupItem metric={CVR} pixelType={IMPRESSION}>
                            {chartInsightById('aadxC7HutNHz')}
                        </GroupItem>
                        <GroupItem metric={CTR} pixelType={CLICK}>
                            {chartInsightById('aaWxmU8XmLC2')}
                        </GroupItem>
                        <GroupItem metric={CVR} pixelType={CLICK}>
                            {chartInsightById('aatxxX0wqpyY')}
                        </GroupItem>
                        <GroupItem metric={CTR} pixelType={CONVERSION}>
                            {chartInsightById('aaNxoG0LmLxD')}
                        </GroupItem>
                        <GroupItem metric={CVR} pixelType={CONVERSION}>
                            {chartInsightById('aacxFaQEW7xR')}
                        </GroupItem>
                        <GroupItem metric={VCR} pixelType={IMPRESSION}>
                            {chartInsightById('aaLt7ZVylEcu')}
                        </GroupItem>
                        <GroupItem metric={VCR} pixelType={CLICK}>
                            {chartInsightById('aapuix1ml793')}
                        </GroupItem>
                        <GroupItem metric={VCR} pixelType={CONVERSION}>
                            {chartInsightById('aanujKqamdwz')}
                        </GroupItem>
                        <GroupItem metric={CTR} pixelType={COMPLETED_VIEWS}>
                            {chartInsightById('aaGt7PJflD2n')}
                        </GroupItem>
                        <GroupItem metric={CVR} pixelType={COMPLETED_VIEWS}>
                            {chartInsightById('aaMt21WyjyHF')}
                        </GroupItem>
                        <GroupItem metric={VCR} pixelType={COMPLETED_VIEWS}>
                            {chartInsightById('aaluiXgrmdGa')}
                        </GroupItem>
                    </div>
                    <div>
                        <GroupItem pixelType={IMPRESSION}>{chartInsightById('aaOxouRgF0mM')}</GroupItem>
                        <GroupItem pixelType={CLICK}>{chartInsightById('aaQxoQaWF0BW')}</GroupItem>
                        <GroupItem pixelType={CONVERSION}>{chartInsightById('aatxFp4XW7Cy')}</GroupItem>
                        <GroupItem pixelType={COMPLETED_VIEWS}>{chartInsightById('aabunueLsckQ')}</GroupItem>
                    </div>
                </Group>
                <div className="col-span-2 flex flex-col gap-8">{chartInsightById('abbkr5zA8m1m')}</div>
            </>
        );
    }, [chartInsightById, insightsFilters]);

    const renderCreativeTab = useMemo(() => {
        return (
            <div>
                <Group>
                    <div className="col-span-2">
                        <GroupItem metric={CTR} pixelType={IMPRESSION}>
                            {chartInsightById('abkpo8aAis8a')}
                        </GroupItem>
                        <GroupItem metric={CVR} pixelType={IMPRESSION}>
                            {chartInsightById('aalpx2bslIJO')}
                        </GroupItem>
                        <GroupItem metric={CTR} pixelType={CLICK}>
                            {chartInsightById('abgpvpiq3633')}
                        </GroupItem>
                        <GroupItem metric={CVR} pixelType={CLICK}>
                            {chartInsightById('aa5pr91vjJyN')}
                        </GroupItem>
                        <GroupItem metric={CTR} pixelType={CONVERSION}>
                            {chartInsightById('abbpxIYv38io')}
                        </GroupItem>
                        <GroupItem metric={CVR} pixelType={CONVERSION}>
                            {chartInsightById('abcpw97b373J')}
                        </GroupItem>
                        <GroupItem metric={VCR} pixelType={IMPRESSION}>
                            {chartInsightById('aavuiW7ymdBN')}
                        </GroupItem>
                        <GroupItem metric={VCR} pixelType={CLICK}>
                            {chartInsightById('aafukRRMrfsh')}
                        </GroupItem>
                        <GroupItem metric={VCR} pixelType={CONVERSION}>
                            {chartInsightById('aalujVuvl706')}
                        </GroupItem>
                        <GroupItem metric={CTR} pixelType={COMPLETED_VIEWS}>
                            {chartInsightById('aacunEhCm6YT')}
                        </GroupItem>
                        <GroupItem metric={CVR} pixelType={COMPLETED_VIEWS}>
                            {chartInsightById('aahukY0Org90')}
                        </GroupItem>
                        <GroupItem metric={VCR} pixelType={COMPLETED_VIEWS}>
                            {chartInsightById('aazuiXgrmdGa')}
                        </GroupItem>
                    </div>
                </Group>
                {chartInsightById('aavpIrZRqqPl')}
            </div>
        );
    }, [chartInsightById]);

    const tabElements: TabElement[] = [
        {
            content: renderPerformanceTab,
            label: 'Performance',
        },
        {
            content: renderCreativeTab,
            label: 'Creative',
        },
    ];

    return (
        <React.Fragment>
            <PageTitle title="Media Delivery" />
            <MediaDeliveryChartsTabSelector onChange={(value: string) => setGroupChartSelector(value)} />
            <div className="mb-8 flex items-center gap-4">
                <AdvertiserFilter
                    onApply={(updatedFilter: IAttributeFilter) => advertiserSetFilters([updatedFilter])}
                />
                <CampaignFilter onApply={(updatedFilter: IAttributeFilter) => campaignSetFilters([updatedFilter])} />
                <CampaignMediaDataCombined
                    onApply={(updatedFilter: IDateFilter) => dateRangeSetFilers([updatedFilter])}
                />
                <DataTableSourceFilter
                    onApply={(updatedFilter: IAttributeFilter) => dataTableSourceSetFilters([updatedFilter])}
                />
                <CreativeFilter onApply={(updatedFilter: IAttributeFilter) => creativeSetFilters([updatedFilter])} />
            </div>
            <div>
                <TabGroup tabs={tabElements} selected={groupChartSelector} />
            </div>
        </React.Fragment>
    );
}
