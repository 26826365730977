import React, { useEffect } from 'react';

import { FAQsProps } from '../../pages/FAQs';
import { IDLE } from '../builders/state';
import { useAppDispatch, useAppSelector } from '../hooks';
import { fetchFAQs } from '../slices/FAQ';

const withFAQContainer = (FAQs: React.FC<FAQsProps>) => {
    const FAQsContainer: React.FC = (props) => {
        const dispatch = useAppDispatch();
        const data = useAppSelector((state) => state.faqs.data);
        const status = useAppSelector((state) => state.faqs.status);

        useEffect(() => {
            if (status === IDLE) {
                dispatch(fetchFAQs());
            }
        }, [status, dispatch]);

        return <FAQs data={data} {...props} />;
    };

    return FAQsContainer;
};

export default withFAQContainer;
