import React from 'react';

import PageTitle from '../../components/display/PageTitle';

export default function AudienceInsights() {
    return (
        <React.Fragment>
            <PageTitle title="Audience Insights" />
            <div className="relative flex h-full w-full">
                <img className="h-full w-full object-cover opacity-50" src="../../images/insights.png" alt="" />
                <div className="absolute inset-0 flex items-center justify-center">
                    <h1 className="text-2xl text-fluorgreen">Coming Soon</h1>
                </div>
            </div>
        </React.Fragment>
    );
}
