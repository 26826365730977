import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { StudioHubDataType } from '../../interfaces/StudioHubTypes';
import { getContentTypeURL } from '../api';
import { initialiseState, setPending, setRejected, setSucceeded, State } from '../builders/state';

export const fetchStudioHub = createAsyncThunk('StudioHub/fetch', async () => {
    const response = await axios.get(getContentTypeURL('gallery-creatives'));
    return response.data;
});

export interface StudioHubState extends State {
    data: StudioHubDataType;
}

export const initialState: StudioHubState = initialiseState({ data: {} });

const StudioHubSlice = createSlice({
    extraReducers: (builder) => {
        builder
            .addCase(fetchStudioHub.pending, setPending)
            .addCase(fetchStudioHub.rejected, setRejected)
            .addCase(fetchStudioHub.fulfilled, (state: StudioHubState, action) => {
                state.data = action.payload;
                setSucceeded(state);
            });
    },
    initialState,
    name: 'StudioHub',
    reducers: {},
});

export default StudioHubSlice.reducer;
