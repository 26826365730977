import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { ApiInstagramImageType } from '../../interfaces/InstagramImageTypes';
import { getContentTypeURL } from '../api';
import { initialiseState, setPending, setRejected, setSucceeded, State } from '../builders/state';

export const fetchInstagramImage = createAsyncThunk('instagram-image/fetch', async () => {
    const response = await axios.get(getContentTypeURL('homepage-instagram-image'));
    return response.data;
});

export interface InstagramImageState extends State {
    data: ApiInstagramImageType;
}

export const initialState: InstagramImageState = initialiseState({ data: {} });

const instagramImageSlices = createSlice({
    extraReducers: (builder) => {
        builder
            .addCase(fetchInstagramImage.pending, setPending)
            .addCase(fetchInstagramImage.rejected, setRejected)
            .addCase(fetchInstagramImage.fulfilled, (state: InstagramImageState, action) => {
                state.data = action.payload;
                setSucceeded(state);
            });
    },
    initialState,
    name: 'instagramImage',
    reducers: {},
});

export default instagramImageSlices.reducer;
