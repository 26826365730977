import React, { useState } from 'react';

type DropdownOption = {
    value: string;
    label: string;
};

export type DropDownChartFilterProps = {
    defaultOption: string;
    options: DropdownOption[];
    onChange: (value: string) => void;
    dataTestId: string;
    label?: string;
};

const DropdownFilter = ({ defaultOption, options, onChange, dataTestId, label }: DropDownChartFilterProps) => {
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value;
        setSelectedOption(newValue);
        onChange(newValue);
    };

    const renderOptions = () => {
        return options.map((option: DropdownOption, index: number) => (
            <option data-testid={`filter-select-option-${index}`} key={option.value} value={option.value}>
                {option.label}
            </option>
        ));
    };

    const renderLabel = () => {
        if (label) {
            return (
                <label className="text-sm" htmlFor={`${dataTestId}-filter`}>
                    {label}
                </label>
            );
        }
    };

    return (
        <div className="flex flex-col gap-2">
            {renderLabel()}
            <select
                className=" h-[1.6875rem] cursor-pointer rounded-sm border border-gray-500 bg-midlightpetrol px-2 py-1 text-xs font-thin text-slate-400"
                value={selectedOption}
                onChange={handleOptionChange}
                data-testid={`${dataTestId}-filter`}
            >
                {renderOptions()}
            </select>
        </div>
    );
};

export default DropdownFilter;
