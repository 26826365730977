import React, { Children, PropsWithChildren, ReactNode } from 'react';

export type StudioHubProps = PropsWithChildren<ReactNode> & {
    total: number;
};

export default function Gallery({ children, total }: StudioHubProps) {
    const filterInformation = () => {
        const filtered = Children.count(children);
        return filtered === total ? total : `${filtered}/${total}`;
    };

    return (
        <div className="flex w-full justify-center">
            <div
                className="grid grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-3 xl:gap-20 2xl:gap-24"
                data-testid="gallery-container"
            >
                {children}
                <div className="col-span-full text-center">Results {filterInformation()}</div>
            </div>
        </div>
    );
}
