export const IDLE = 'idle';
export const LOADING = 'loading';
export const SUCCEEDED = 'succeeded';
export const FAILED = 'failed';

export interface State {
    status: typeof IDLE | typeof LOADING | typeof FAILED | typeof SUCCEEDED;
}

export const initialiseState = <SliceState>(state: SliceState): SliceState & State => {
    return { ...state, status: IDLE };
};

export const setPending = <SliceState extends State>(state: SliceState) => {
    state.status = LOADING;
};

export const setRejected = <SliceState extends State>(state: SliceState) => {
    state.status = FAILED;
};

export const setSucceeded = <SliceState extends State>(state: SliceState) => {
    state.status = SUCCEEDED;
};
