import { IDateFilter } from '@gooddata/sdk-model';

import { filterDefinition } from './data/CampaignMediaDataCombined';
import DateRange from './DateRange';

type CampaignMediaDataCombinedProps = {
    onApply: (filter: IDateFilter) => void;
};

export default function CampaignMediaDataCombined({ onApply }: CampaignMediaDataCombinedProps) {
    return (
        <div data-testid="campaign-media-data-combined-date-range-filter">
            <DateRange definition={filterDefinition} onApply={onApply} customFilterName="Date Filter" />
        </div>
    );
}
