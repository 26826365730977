import React, { useState } from 'react';

type optionType = {
    value: string;
    label: string;
};

type TabOptionsProps = {
    defaultOption: string;
    options: optionType[];
    onChange: (value: string) => void;
};

export default function TabOptions({ defaultOption, options, onChange }: TabOptionsProps) {
    const selectedStyle = 'bg-darkfluorgreen text-sm text-fluorgreen border-darkfluorgreen';
    const unselectedStyle = 'text-sm text-gray-400 hover:border-darkgray';
    const commonStyle = 'rounded-lg text-sm px-4 py-1 border border-transparent border-solid';
    const [selected, setSelected] = useState(defaultOption);
    const handleOptionChange = (e: React.MouseEvent<HTMLButtonElement>) => {
        const newValue = e.currentTarget.value;
        setSelected(newValue);
        onChange(newValue);
    };
    return (
        <div className="flex gap-4" data-testid="tab-options-selector">
            {options.map((option: optionType, index: number) => (
                <button
                    className={`${commonStyle} ${selected === option.value ? selectedStyle : unselectedStyle}`}
                    value={option.value}
                    onClick={handleOptionChange}
                    data-testid={`filter-select-option-${index}`}
                    key={option.value}
                >
                    {option.label}
                </button>
            ))}
        </div>
    );
}
