import React, { ReactNode, useEffect } from 'react';

import DotIcon from '../../../icons/Dot';
import Slide from './Slide';

type SliderProps = {
    children: ReactNode[];
    time: number;
};

export default function Slider({ children, time }: SliderProps) {
    const [index, setIndex] = React.useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            const newIndex = (index + 1) % children.length;
            setIndex(newIndex);
        }, time);
        return () => clearInterval(interval);
    });

    const slides = () => {
        return children.map((child, idx) => (
            <Slide key={idx} id={idx} selectedKey={index}>
                {child}
            </Slide>
        ));
    };

    const sliderDots = () => {
        return children.map((_, key) => (
            <span key={key} onClick={() => setIndex(key)} className="cursor-pointer">
                {<DotIcon selected={key === index} index={key} />}
            </span>
        ));
    };

    return (
        <div className="flex flex-col">
            <div data-testid={'slider'}>{slides()}</div>
            <div className="mt-4 flex justify-center" data-testid={'slider-selector'}>
                {sliderDots()}
            </div>
        </div>
    );
}
