import { ReactNode, useState } from 'react';

import Body from './Body';
import Title from './Title';

type AccordionProps = {
    title: string;
    children: ReactNode;
};

const ExpandCollapse = ({ title, children }: AccordionProps) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <>
            <Title isOpen={expanded} onClick={() => setExpanded(!expanded)}>
                {title}
            </Title>
            {expanded && <Body>{children}</Body>}
        </>
    );
};

export default ExpandCollapse;
