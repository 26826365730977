import React, { useState } from 'react';
import { useMatch } from 'react-router';
import { NavLink } from 'react-router-dom';

import { MenuOption } from '../../interfaces/NavigationTypes';

type NavElementProps = {
    menu: MenuOption;
    index: number;
    onClick?: (newValue: React.MouseEvent) => void;
};

const NavElement = ({ menu, onClick }: NavElementProps) => {
    const [expanded, setExpanded] = useState(Boolean(useMatch({ end: false, path: menu.path })));
    const onClickAction = onClick ? onClick : undefined;

    const clickableNavLinkStyle =
        'text-lightbluegray text-xl lg:text-sm hover:no-underline flex cursor-pointer items-center p-3 hover:bg-darkpetrol';

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setExpanded(!expanded);
        event.preventDefault();
    };

    const navLinkStyleIfActive = (isActive: boolean) => {
        const selectedStyle = ' border-r-4 border-fluorgreen bg-darkpetrol text-fluorgreen';
        return clickableNavLinkStyle + (isActive ? selectedStyle : '');
    };

    const subLinks = () => menu.subMenu.map((subMenu: MenuOption, index: number) => submenuLink(subMenu, index));

    const submenuLink = (menu: MenuOption, index: number) => (
        <NavLink
            to={menu.path}
            key={'submenu-' + index}
            className={({ isActive }) => `pl-16 ${navLinkStyleIfActive(isActive)}`}
            onClick={onClickAction}
        >
            {menu.title}
        </NavLink>
    );

    const mainLinkPadding = 'pl-12';
    return (
        <React.Fragment>
            <NavLink
                to={menu.path}
                className={({ isActive }) =>
                    menu.subMenu.length
                        ? `${mainLinkPadding} ${clickableNavLinkStyle}`
                        : `${mainLinkPadding} ${navLinkStyleIfActive(isActive)}`
                }
                onClick={menu.subMenu.length ? handleClick : onClickAction}
            >
                {menu.title}
            </NavLink>
            <div className={expanded ? 'block' : 'lg:hidden'}>{subLinks()}</div>
        </React.Fragment>
    );
};

export default NavElement;
