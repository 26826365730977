import { IAttribute, IAttributeFilter, newAttribute, newPositiveAttributeFilter } from '@gooddata/sdk-model';
import { useState } from 'react';

import AttributeFilter from './Attribute';

type AdvertiserFilterProps = {
    onApply: (filter: IAttributeFilter) => void;
};

export default function AdvertiserFilter(props: AdvertiserFilterProps) {
    const attribute: IAttribute = newAttribute('label.platform_advertisers.name');
    const defaultFilter = newPositiveAttributeFilter(attribute, { uris: [] });

    const [filter, setFilter] = useState<IAttributeFilter>(defaultFilter);

    const onApply = (filter: IAttributeFilter) => {
        props.onApply(filter);
        setFilter(filter);
    };

    return (
        <div data-testid="advertiser-filter">
            <AttributeFilter {...props} filter={filter} onApply={onApply} title="Advertiser" />
        </div>
    );
}
