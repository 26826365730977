const Expand = () => {
    return (
        <svg role="icon" xmlns="http://www.w3.org/2000/svg" width="23.511" height="24.972" viewBox="0 0 23.511 24.972">
            <title>expand</title>
            <path
                id="Path_30575"
                data-name="Path 30575"
                d="M15.389-5.181h8.952V.049H15.389v10.2H9.829V.049h-9v-5.23h9v-9.541h5.56Z"
                transform="translate(-0.83 14.722)"
                fill="#fff"
            />
        </svg>
    );
};

export default Expand;
