import { HashRouter, Route, Routes } from 'react-router-dom';

import Layout from './components/Layout';
import AudienceManager from './pages/AudienceManager';
import FAQs from './pages/FAQs';
import Home from './pages/Home';
import AudienceInsights from './pages/reporting/AudienceInsights';
import MediaDelivery from './pages/reporting/MediaDelivery';
import Trends from './pages/reporting/Trends';
import Resources from './pages/Resources';
import Settings from './pages/Settings';
import StudioHub from './pages/StudioHub';
import withFAQContainer from './store/containers/FAQContainer';
import withHomeContainer from './store/containers/HomeContainer';
import withResourcesContainer from './store/containers/Resources';
import withStudioHubContainer from './store/containers/StudioHubContainer';

const HomeContainer = withHomeContainer(Home);
const EnhancedStudioHub = withStudioHubContainer(StudioHub);
const FAQContainer = withFAQContainer(FAQs);
const ResourcesContainer = withResourcesContainer(Resources);

function App() {
    const reportingBasePath = '/reporting';
    return (
        <HashRouter>
            <Layout>
                <Routes>
                    <Route path="/" element={<HomeContainer />} />
                    <Route path={`${reportingBasePath}/media-delivery`} element={<MediaDelivery />} />
                    <Route path={`${reportingBasePath}/trends`} element={<Trends />} />
                    <Route path={`${reportingBasePath}/audience-insights`} element={<AudienceInsights />} />
                    <Route path="/studio-hub" element={<EnhancedStudioHub />} />
                    <Route path="/resources" element={<ResourcesContainer />} />
                    <Route path="/audience-manager" element={<AudienceManager />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/faqs" element={<FAQContainer />} />
                </Routes>
            </Layout>
        </HashRouter>
    );
}

export default App;
